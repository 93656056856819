import request from '@/utils/request'


// 查询投资计算器列表
export function listCalculator(query) {
  return request({
    url: '/investment/investment-calculator/list',
    method: 'get',
    params: query
  })
}

// 查询投资计算器分页
export function pageCalculator(query) {
  return request({
    url: '/investment/investment-calculator/page',
    method: 'get',
    params: query
  })
}

// 查询投资计算器详细
export function getCalculator(data) {
  return request({
    url: '/investment/investment-calculator/detail',
    method: 'get',
    params: data
  })
}

// 新增投资计算器
export function addCalculator(data) {
  return request({
    url: '/investment/investment-calculator/add',
    method: 'post',
    data: data
  })
}

// 修改投资计算器
export function updateCalculator(data) {
  return request({
    url: '/investment/investment-calculator/edit',
    method: 'post',
    data: data
  })
}

// 删除投资计算器
export function delCalculator(data) {
  return request({
    url: '/investment/investment-calculator/delete',
    method: 'post',
    data: data
  })
}
