<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户id,外键{zb_user.id}" prop="userId">
                <a-input v-model="queryParam.userId" placeholder="请输入用户id,外键{zb_user.id}" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">
              <a-form-item label="土地地址" prop="address">
                <a-input v-model="queryParam.address" placeholder="请输入土地地址" allow-clear/>
              </a-form-item>
            </a-col>-->
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="土地大小" prop="landArea">
                  <a-input v-model="queryParam.landArea" placeholder="请输入土地大小" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="房屋价值" prop="homeValue">
                  <a-input v-model="queryParam.homeValue" placeholder="请输入房屋价值" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="贷款金额" prop="loanAmount">
                  <a-input v-model="queryParam.loanAmount" placeholder="请输入贷款金额" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="容积率" prop="plotRatio">
                  <a-input v-model="queryParam.plotRatio" placeholder="请输入容积率" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="可建面积" prop="builtArea">
                  <a-input v-model="queryParam.builtArea" placeholder="请输入可建面积" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="建后增加价值" prop="increasePrices">
                  <a-input v-model="queryParam.increasePrices" placeholder="请输入建后增加价值" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="总成本" prop="totalCost">
                  <a-input v-model="queryParam.totalCost" placeholder="请输入总成本" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="毛利润" prop="grossProfit">
                  <a-input v-model="queryParam.grossProfit" placeholder="请输入毛利润" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="建后总价值" prop="builtTotalPrice">
                  <a-input v-model="queryParam.builtTotalPrice" placeholder="请输入建后总价值" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="现有本金" prop="principal">
                  <a-input v-model="queryParam.principal" placeholder="请输入现有本金" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="现有贷款" prop="loan">
                  <a-input v-model="queryParam.loan" placeholder="请输入现有贷款" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="最高贷款" prop="highestLoan">
                  <a-input v-model="queryParam.highestLoan" placeholder="请输入最高贷款" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="现有可使用资金" prop="availableFunds">
                  <a-input v-model="queryParam.availableFunds" placeholder="请输入现有可使用资金" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="所需资金" prop="requiredFunds">
                  <a-input v-model="queryParam.requiredFunds" placeholder="请输入所需资金" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['investment:calculator:add']">
          <a-icon type="plus" />新增
        </a-button>-->
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['investment:calculator:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['investment:calculator:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['investment:calculator:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['investment:calculator:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['investment:calculator:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['investment:calculator:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['investment:calculator:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageCalculator,listCalculator, delCalculator } from '@/api/investment/calculator'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Calculator',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        address: null,
        landArea: null,
        homeValue: null,
        loanAmount: null,
        plotRatio: null,
        builtArea: null,
        increasePrices: null,
        totalCost: null,
        grossProfit: null,
        builtTotalPrice: null,
        principal: null,
        loan: null,
        highestLoan: null,
        availableFunds: null,
        requiredFunds: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: '投资计算器id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '用户id,外键{zb_user.id}',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '土地地址',
          dataIndex: 'address',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '土地大小',
          dataIndex: 'landArea',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '房屋价值',
          dataIndex: 'homeValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '贷款金额',
          dataIndex: 'loanAmount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '容积率',
          dataIndex: 'plotRatio',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '可建面积',
          dataIndex: 'builtArea',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '建后增加价值',
          dataIndex: 'increasePrices',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '总成本',
          dataIndex: 'totalCost',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '毛利润',
          dataIndex: 'grossProfit',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '建后总价值',
          dataIndex: 'builtTotalPrice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '现有本金',
          dataIndex: 'principal',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '现有贷款',
          dataIndex: 'loan',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最高贷款',
          dataIndex: 'highestLoan',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '现有可使用资金',
          dataIndex: 'availableFunds',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '所需资金',
          dataIndex: 'requiredFunds',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询投资计算器列表 */
    getList () {
      this.loading = true
     pageCalculator(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        address: undefined,
        landArea: undefined,
        homeValue: undefined,
        loanAmount: undefined,
        plotRatio: undefined,
        builtArea: undefined,
        increasePrices: undefined,
        totalCost: undefined,
        grossProfit: undefined,
        builtTotalPrice: undefined,
        principal: undefined,
        loan: undefined,
        highestLoan: undefined,
        availableFunds: undefined,
        requiredFunds: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delCalculator(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('investment/investment-calculator/export', {
            ...that.queryParam
          }, `投资计算器_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
