<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id,外键{zb_user.id}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id,外键{zb_user.id}" />
      </a-form-model-item>
      <a-form-model-item label="土地地址" prop="address" >
        <a-input v-model="form.address" placeholder="请输入土地地址" />
      </a-form-model-item>
      <a-form-model-item label="土地大小" prop="landArea" >
        <a-input v-model="form.landArea" placeholder="请输入土地大小" />
      </a-form-model-item>
      <a-form-model-item label="房屋价值" prop="homeValue" >
        <a-input v-model="form.homeValue" placeholder="请输入房屋价值" />
      </a-form-model-item>
      <a-form-model-item label="贷款金额" prop="loanAmount" >
        <a-input v-model="form.loanAmount" placeholder="请输入贷款金额" />
      </a-form-model-item>
      <a-form-model-item label="容积率" prop="plotRatio" >
        <a-input v-model="form.plotRatio" placeholder="请输入容积率" />
      </a-form-model-item>
      <a-form-model-item label="可建面积" prop="builtArea" >
        <a-input v-model="form.builtArea" placeholder="请输入可建面积" />
      </a-form-model-item>
      <a-form-model-item label="建后增加价值" prop="increasePrices" >
        <a-input v-model="form.increasePrices" placeholder="请输入建后增加价值" />
      </a-form-model-item>
      <a-form-model-item label="总成本" prop="totalCost" >
        <a-input v-model="form.totalCost" placeholder="请输入总成本" />
      </a-form-model-item>
      <a-form-model-item label="毛利润" prop="grossProfit" >
        <a-input v-model="form.grossProfit" placeholder="请输入毛利润" />
      </a-form-model-item>
      <a-form-model-item label="建后总价值" prop="builtTotalPrice" >
        <a-input v-model="form.builtTotalPrice" placeholder="请输入建后总价值" />
      </a-form-model-item>
      <a-form-model-item label="现有本金" prop="principal" >
        <a-input v-model="form.principal" placeholder="请输入现有本金" />
      </a-form-model-item>
      <a-form-model-item label="现有贷款" prop="loan" >
        <a-input v-model="form.loan" placeholder="请输入现有贷款" />
      </a-form-model-item>
      <a-form-model-item label="最高贷款" prop="highestLoan" >
        <a-input v-model="form.highestLoan" placeholder="请输入最高贷款" />
      </a-form-model-item>
      <a-form-model-item label="现有可使用资金" prop="availableFunds" >
        <a-input v-model="form.availableFunds" placeholder="请输入现有可使用资金" />
      </a-form-model-item>
      <a-form-model-item label="所需资金" prop="requiredFunds" >
        <a-input v-model="form.requiredFunds" placeholder="请输入所需资金" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCalculator, addCalculator, updateCalculator } from '@/api/investment/calculator'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        address: null,

        landArea: null,

        homeValue: null,

        loanAmount: null,

        plotRatio: null,

        builtArea: null,

        increasePrices: null,

        totalCost: null,

        grossProfit: null,

        builtTotalPrice: null,

        principal: null,

        loan: null,

        highestLoan: null,

        availableFunds: null,

        requiredFunds: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id,外键{zb_user.id}不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '土地地址不能为空', trigger: 'blur' }
        ],
        landArea: [
          { required: true, message: '土地大小不能为空', trigger: 'blur' }
        ],
        homeValue: [
          { required: true, message: '房屋价值不能为空', trigger: 'blur' }
        ],
        loanAmount: [
          { required: true, message: '贷款金额不能为空', trigger: 'blur' }
        ],
        plotRatio: [
          { required: true, message: '容积率不能为空', trigger: 'blur' }
        ],
        builtArea: [
          { required: true, message: '可建面积不能为空', trigger: 'blur' }
        ],
        increasePrices: [
          { required: true, message: '建后增加价值不能为空', trigger: 'blur' }
        ],
        totalCost: [
          { required: true, message: '总成本不能为空', trigger: 'blur' }
        ],
        grossProfit: [
          { required: true, message: '毛利润不能为空', trigger: 'blur' }
        ],
        builtTotalPrice: [
          { required: true, message: '建后总价值不能为空', trigger: 'blur' }
        ],
        principal: [
          { required: true, message: '现有本金不能为空', trigger: 'blur' }
        ],
        loan: [
          { required: true, message: '现有贷款不能为空', trigger: 'blur' }
        ],
        highestLoan: [
          { required: true, message: '最高贷款不能为空', trigger: 'blur' }
        ],
        availableFunds: [
          { required: true, message: '现有可使用资金不能为空', trigger: 'blur' }
        ],
        requiredFunds: [
          { required: true, message: '所需资金不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        address: null,
        landArea: null,
        homeValue: null,
        loanAmount: null,
        plotRatio: null,
        builtArea: null,
        increasePrices: null,
        totalCost: null,
        grossProfit: null,
        builtTotalPrice: null,
        principal: null,
        loan: null,
        highestLoan: null,
        availableFunds: null,
        requiredFunds: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCalculator({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCalculator(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCalculator(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
